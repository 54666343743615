<template>
  <div class="my-box">
    <van-cell title="我的学员">
      <template #title>
        <div class="person-img-box">
          <span>{{ user.name }}</span>
        </div>
      </template>
    </van-cell>
    <div class="person-item">
      <van-cell title="真实姓名" :value="user.name" />
      <van-cell title="身份证号" :value="user.idCard" />
      <van-cell title="手机号" :value="user.phone" />
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup, Button, Icon } from "vant";
import { selectTeacherInfo } from "@/api/my";
import { getSessionStorage } from "@/utils/cache";
export default {
  components: {
    [Cell.name]: Cell,
    // [CellGroup.name]: CellGroup,
    // [Button.name]: Button,
    // [Icon.name]: Icon,
  },
  data() {
    return {
      user: {},
    };
  },
  async created() {
    document.title = "个人中心";
    this.$store.commit("updateBarText", "个人中心");
    await this.init();
  },
  methods: {
    async init() {
      selectTeacherInfo().then(res => {
        const { data } = res
        this.user = data.data
      })
    },
  },
};
</script>

<style lang="less" scoped>
.my-box {
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  padding-top: 50px;
  .van-cell {
    height: 50px;
    border-bottom: 1px solid #203152;
  }
  .van-cell__title {
    color: #203152;
    font-size: 16px;
    font-family: PingFangSC-Regular, sans-serif;
  }
  .van-cell__value {
    color: #203152;
    font-size: 16px;
    font-family: PingFangSC-Regular, sans-serif;
  }
  .van-icon {
    color: #203152;
    font-size: 14px;
  }
  .person-img-box {
    height: 50px;
    width: 50px;
  }
  .person-item {
    .van-cell {
      height: 50px;
      border-bottom: 1px solid #203152;
    }
  }
}
</style>